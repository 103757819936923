import { Injectable } from '@angular/core';
import { arrayRemove, arrayUnion, where } from '@angular/fire/firestore';
import { Club } from '@models/clubs';
import { COLLECTIONS } from '@models/collections';
import { Season } from '@models/competitions';
import { Scope, WeekDay } from '@models/masters';
import { Team } from '@models/teams';
import { Observable, combineLatest, firstValueFrom, map, shareReplay, switchMap } from 'rxjs';
import { ClubsService } from './club.service';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { SeasonsService } from './seasons.service';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  private COLLECTION = COLLECTIONS.TEAMS;

  private teams$ = this.seasonManagerService.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocuments<Team>(this.COLLECTION, where('seasonYear', '==', seasonYear)).pipe(shareReplay(1)),
    ),
  );

  public all$: Observable<(Team & { club?: Club; season?: Season })[]> = this.seasonManagerService.season$.pipe(
    switchMap((seasonYear) =>
      combineLatest([
        this.teams$.pipe(map((teams) => teams.filter((team) => team.seasonYear === seasonYear))),
        this.clubsService.all$,
        this.seasonsService.all$,
      ]),
    ),
    map(([teams, clubs, seasons]) =>
      teams.map((team) => ({
        ...team,
        club: team.clubId ? clubs.find((club) => club.id === team.clubId) : undefined,
        season: seasons.find((s) => s.id === team.seasonId),
      })),
    ),
    shareReplay(1),
  );

  public clubsWithTeams$: Observable<(Club & { teams: Team[] })[]> = combineLatest([
    this.clubsService.all$,
    this.teams$,
  ]).pipe(
    map(([clubs, teams]) => {
      return clubs.map((club) => {
        return {
          ...club,
          teams: teams.filter((team) => team.clubId === club.id),
        };
      });
    }),
    shareReplay(1),
  );

  constructor(
    private firestore: FirestoreService,
    private clubsService: ClubsService,
    private seasonsService: SeasonsService,
    private seasonManagerService: SeasonManagerService,
  ) {}

  get(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.all$.pipe(map((teams) => teams.find((club) => club.id === id)!));
  }

  getByClubAndScope(clubId: string, scope: string) {
    return this.all$.pipe(map((teams) => teams.filter((team) => team.clubId === clubId && scope === team.scope)));
  }

  getByScope(scope: Scope) {
    return this.all$.pipe(map((teams) => teams.filter((team) => scope === team.scope)));
  }

  getByClub(clubId: string) {
    return this.all$.pipe(map((teams) => teams.filter((team) => team.clubId === clubId)));
  }

  create(user: Omit<Team, 'id'>) {
    return this.firestore.create(this.COLLECTION, user);
  }

  update(id: string, user: Partial<Team>) {
    return this.firestore.update(this.COLLECTION, {
      id,
      ...user,
    });
  }

  async createOrUpdate(id: string | undefined, team: Omit<Team, 'id'>) {
    const season = await firstValueFrom(this.seasonsService.get(team.seasonId));
    team.scope = season.scope;
    team.seasonYear = this.seasonManagerService.getActualSeason();
    return id ? this.update(id, team) : this.create(team);
  }

  getReport() {
    return this.firestore.listDocuments<Team>(this.COLLECTION);
  }

  deleteForever(teamId: string) {
    return this.firestore.deleteForever(this.COLLECTION, teamId);
  }

  deleteDelegate(teamId: string, userId: string) {
    return this.firestore.update(this.COLLECTION, { id: teamId, delegatesIds: arrayRemove(userId) });
  }
  addDelegate(teamId: string, userId: string) {
    return this.firestore.update(this.COLLECTION, { id: teamId, delegatesIds: arrayUnion(userId) });
  }

  private async deletePlayer(teamId: string, userId: string) {
    return await this.firestore.update(this.COLLECTION, { id: teamId, playersIds: arrayRemove(userId) });
  }
  private async addPlayer(teamId: string, userId: string) {
    return await this.firestore.update(this.COLLECTION, { id: teamId, playersIds: arrayUnion(userId) });
  }

  deleteTimeSlotTraining(teamId: string, time: { day: WeekDay; time: string }) {
    return this.firestore.update(this.COLLECTION, { id: teamId, timeSlotsTraining: arrayRemove(time) });
  }

  async changeTeamPlayer(userId: string, fromTeamId: string, toTeamId: string) {
    await this.deletePlayer(fromTeamId, userId);
    await this.addPlayer(toTeamId, userId);
  }
}
